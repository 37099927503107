import React from 'react'

const Passenger = (props) => {
    const { isMissingData = false } = props
    const { FirstName, LastName, Phone, Email } = props.pax
    const emailToShow = Email?._text === 'undefined' || Email?._text === '' || Object.keys(Email).length === 0 ? 'אין מידע' : Email?._text
    const style = {
        backgroundColor: isMissingData ? '#dc354585' : 'white'
    }
    return (
        <div className="passenger" style={style} >
            <div className="passenger-name">
                <span>שם: {`${FirstName?._text} ${LastName?._text}`}</span>
            </div>
            <div className="passenger-email">
                <span>אימייל: {`${emailToShow}`}</span>
            </div>
            <div className="passenger-phone">
                <span>טלפון: {Phone?._text ? `${Phone?._text}` : 'מספר טלפון לא קיים'}</span>
            </div>
            <div className="passenger-delete">
                {/* <XInsideSolidCircle /> */}
            </div>
        </div>
    )
}

export default Passenger