import {
    GET_CUSTOM_EMAILS,
    GET_CUSTOM_EMAIL,
    ADD_CUSTOM_EMAIL,
    EDIT_CUSTOM_EMAIL,
    DELETE_CUSTOM_EMAIL,
    FLIGHT_ERROR,
    CLEAR_CUSTOM_EMAIL,
    ADD_FILES,
    GET_PDF_NAMES,
    LOADING_START,
    LOADING_END,
    DONE_UPLOADING,
    START_UPLOADING,
    UPLOAD_PROGRESS
} from './customMailTypes';
import axios from 'axios';
import { setAlert } from '../alert/alertAction';
// import balanceAnswerMock from '../../mock/balanceAnswerMock.js'
import kavAnswerJson from '../../mock/kavAnswerJson.js'
import { xml2json } from "xml-js";
import { setXmlToJson, changeEmailsFromKavSystemToCorrectFormat } from '../flight/flightAction'
window.Buffer = window.Buffer || require("buffer").Buffer;
// var convert = require('xml-js');


const replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
        if (typeof value === "object" && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};
export const addCustomMail = (currentState, formData) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.token
        }
    };
    try {
        dispatch({ type: START_UPLOADING });
        dispatch({ type: LOADING_START });

        const currentStateCopy = { ...currentState };
        currentStateCopy.wysiwyg = JSON.stringify(currentState.wysiwyg, replacerFunc());
        debugger
        // KAV
        const kavData = await axios.post('api/kav', { currentState: currentStateCopy }, config);

        // const kavData = await axios.post('api/kav', { currentState: currentState });
        let xml = kavData.data.replace(/[\n\r]/g, '\\n').replace(/&/g, "&amp;").replace(/-/g, "&#45;");
        if (xml.includes("error code")) {
            xml = xml.replace('<?xml version="1.0"?>', '');
            let a = xml.indexOf("<origionalMessage>")
            let b = xml.indexOf("</errorMessage")
            let firstSub = xml.substring(0, a);
            let secondSub = xml.substring(b, xml.length);
            xml = firstSub + secondSub;
            let responseError = await setXmlToJson(xml)
            dispatch(setAlert('שגיאה בקבלת נתונים מהמערכת הקבוצתית/קו   ', 'danger'));
            dispatch(setAlert(`השגיאה: ${responseError.errorMessage.error._attributes.code}`, 'danger'));
            await dispatch({ type: CLEAR_CUSTOM_EMAIL });
            dispatch({
                type: LOADING_END
            })
            return -1;
        }

        let kavDataJson = await setXmlToJson(xml)
        // let kavDataJson = kavAnswerJson;

        changeEmailsFromKavSystemToCorrectFormat(kavDataJson);
        currentState.kavData = kavDataJson;

        try {
            // FILES
            //!! TODO ! - check if exist, if not skip this process 
            const filesPath = await dispatch(uploadFiles(formData))//upload files to server
            if (filesPath) {
                currentState.pdfName = filesPath.filesNames;
                currentState.pdfFiles = filesPath.pdfFiles;
                currentState.customPath = filesPath.pdfFiles.map(pdfFile => {
                    let t = pdfFile.split('routes');
                    return t[1]
                })
            }
            currentState.folderName = formData.get('folderName');
        } catch (error) {
            console.log(error)
        }

        let passengerList = []
        currentState.kavData.Root.Body.DocketList.Docket.forEach(docket => {
            // if (docket?.Balance?.Currency?.Difference?._text <= 5) {
            if (docket.Paxes.Pax.constructor === Array)
                docket.Paxes.Pax.forEach(pax => {
                    passengerList.push({ firstName: pax.FirstName._text, lastName: pax.LastName._text, email: pax.Email._text })
                })
            else {
                let pax = docket.Paxes.Pax;
                passengerList.push({ firstName: pax.FirstName._text, lastName: pax.LastName._text, email: pax.Email._text })
            }
            // }
        })
        currentState.passengers = passengerList;

        let passengerMashkit = {
            email: "annak@senior.co.il",
            firstName: "Anna",
            lastName: "Senior"
        }
        // let passengerMashkit = {
        //     email: "amitashdot@gmail.com",
        //     firstName: "Maria",
        //     lastName: "Lauterbach"
        // }
        currentState.passengers.push(passengerMashkit)

        //send mail to user
        let mailSent = await axios.post('api/mail/custom', currentState, config);
        currentState.mailSent = mailSent.data.msg
        currentState.passengers = mailSent.data.data;

        //alert the passenger data by status
        let passengerData = currentState.passengers.map(passenger => {
            let passengerStatus = passenger.status === 2 ? 'נשלח בהצלחה' : 'נכשל';
            let passengerName = passenger.firstName + ' ' + passenger.lastName;
            let passengerEmail = passenger.email;
            let passengerData = { passengerStatus, passengerName, passengerEmail }
            return passengerData;
        })

        let table = "";
        table += "סטאטוס שליחה\t שם נוסע\t אימייל\n"; // create table headers
        for (let i = 0; i < passengerData.length; i++) {
            table += `${passengerData[i].passengerStatus}\t ${passengerData[i].passengerName}\t ${passengerData[i].passengerEmail}\n`; // add table rows
        }

        alert(table); // display the table in an alert dialog box

        await dispatch(setAlert('המיילים נשלחו בהצלחה', 'success'));
        await dispatch({ type: DONE_UPLOADING, });
        await dispatch({ type: CLEAR_CUSTOM_EMAIL });
        await dispatch({ type: LOADING_END })

    } catch (err) {
        console.log(err);
        await dispatch({ type: CLEAR_CUSTOM_EMAIL });
        dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response, status: 'danger' }
        });
        await dispatch({ type: DONE_UPLOADING, });
        dispatch({ type: LOADING_END })
        const errors = err.response.data;
        if (errors) { await dispatch(setAlert('הפעולה נכשלה', 'danger')); }

    }
}



export const uploadFiles = formData => async dispatch => {
    try {
        dispatch({
            type: LOADING_START
        })
        const config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                dispatch({
                    type: UPLOAD_PROGRESS,
                    payload: percentCompleted
                })
            },
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-auth-token': localStorage.token
            }
        };

        const res = await axios.post(`/api/files/mail`, formData, config);
        let responseData = res.data.data;
        let finaleData = { pdfFiles: [], filesNames: [] };

        if (responseData.constructor === Array) {
            responseData.forEach(data => {
                finaleData.pdfFiles.push(data.uploadPath);
                let fileName = data.uploadPath.split('/');
                finaleData.filesNames.push(fileName[fileName.length - 1]);
            });
        } else {
            finaleData.pdfFiles.push(responseData);
            let fileName = responseData.split('/');
            finaleData.filesNames.push(fileName[fileName.length - 1]);
        }

        await dispatch({
            type: ADD_FILES,
            payload: finaleData
        });
        await dispatch({ type: LOADING_END })
        await dispatch(setAlert('הקבצים עלו לשרת', 'success'));
        return finaleData;
    }
    catch (err) {
        console.log(err)
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
        }
        await dispatch({
            type: FLIGHT_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
        await dispatch({ type: LOADING_END })
    }
}