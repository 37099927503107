import React from 'react'
import Passenger from './Passenger'

const ShowPassengerWithNoPhone = (props) => {
    const { kav, res } = props

    function numberWithCommas(num) {
        if (num === null || num === 'undefined') return -1
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    const dataToShow = kav ? Object.keys(kav).length > 2 ? kav?.map((docket, index) => {
        let currency = docket.Balance.Currency
        if (currency.length > 1) {
            //go through all currency.paid._text and sum it
            let sumPaid = 0
            let sumDifference = 0
            currency.forEach((curr) => {
                sumPaid += Number(curr.Paid._text)
                sumDifference += Number(curr.Difference._text)
            })
            currency = { Paid: { _text: sumPaid }, Difference: { _text: sumDifference } }
        }
        return (
            <div className={`docket-card`} key={`out-${index}`}>
                <div className="docket-card-top">
                    <div className="docket-card-top-left">
                        <p className='docket-id docket-card-top-bg'>מספר הדוקט: {docket.ID._text} </p>
                        <p className='docket-paid docket-card-top-bg'>עלות: {numberWithCommas(currency?.Paid?._text)} </p>
                        <p className='docket-difference docket-card-top-bg'>יתרה: {numberWithCommas(currency?.Difference?._text)} </p>
                    </div>
                </div>
                <div className='docket-card-bottom'>
                    {docket.Paxes?.Pax?.length > 1 ?
                        docket.Paxes.Pax.map((pax, i) => {
                            return Object.keys(pax.Phone).length === 0 ?
                                <Passenger pax={pax} isMissingData={true} key={`inner-${index}-${i}`} />
                                :
                                <Passenger pax={pax} key={`inner-${index}-${i}`} />
                        })
                        :
                        Object.keys(docket.Paxes?.Pax?.Phone).length === 0 ?
                            <Passenger pax={docket.Paxes.Pax} isMissingData={true} key={`inner-${index}`} />
                            :
                            <Passenger pax={docket.Paxes.Pax} key={`inner-${index}`} />
                    }
                </div>
            </div>)

    }) : 'לשלוח קוד טיול לקבל פרטים' : null


    return (
        <div className="dockets">
            {dataToShow}
        </div>
    )
}

export default ShowPassengerWithNoPhone