import {
    LOADING_START,
    LOADING_STOP,
    GET_PDF_FILE,
    PDF_ERROR
} from './pdfTypes';
import axios from 'axios';
import { setAlert } from '../alert/alertAction';
// import balanceAnswerMock from '../../mock/balanceAnswerMock.js'
// import kavAnswerJson from '../../mock/kavAnswerJson.js'
import { xml2json } from "xml-js";

window.Buffer = window.Buffer || require("buffer").Buffer;
// var convert = require('xml-js');


export const getProtectedPdf = (password, filePath) => async dispatch => {
    try {
        // const res = await axios.get(`/files/get-pdf/${filePath}`, { params: { password, filePath } })
        // const res = await axios.get(`/files/get-pdf/${filePath}`)
        // const res = await axios.get(`/files/get-pdf?filePath=${encodeURIComponent(filePath)}`, { filePath });
        // const res = await axios.get('/files/get-pdf', { params: { filePath } });
        // const res = await axios.get(`/files/get-pdf?filePath=${encodeURIComponent(filePath)}`, { params: { password } });
        const res = await axios.get(`/files/get-pdf?filePath=${filePath}`);

        const pdfData = res.data;

        dispatch({
            type: GET_PDF_FILE,
            payload: pdfData
        })
    } catch (err) {
        dispatch({
            type: PDF_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        })
    }
}