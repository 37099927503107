import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'
import logo1 from '../../assets/img/logo1.webp'
import logo2 from '../../assets/img/logo2.webp'

const Navbar = () => {
    const authState = useSelector(state => state.auth);
    const dispatch = useDispatch();

    //set usestate for url use useeffect to get the url and update the url state
    const [url, setUrl] = useState(window.location.href);
    useEffect(() => {
        setUrl(window.location.href)
    }, [window.location.href])


    const onLogout = () => {
        dispatch({ type: 'LOGOUT' });
    }

    useEffect(() => {
    }, [authState.isAuthenticated])
    // const isActive = //
    const authLinks = authState?.user?.role === 'admin' ? (
        <ul className="navbar__links">
            <li >
                <NavLink to='/' className="navbar__link">בית</NavLink>
            </li>
            <li >
                <NavLink to='/contact-customers' className="navbar__link" >שליחת הודעות</NavLink>
            </li>
            <li >
                <NavLink to='/landing' className="navbar__link" >היסטוריית פעולות</NavLink>
            </li>
            {/* <li >
                <NavLink to='/control' className="navbar__link" >Control</NavLink>
            </li> */}
            <li >
                <NavLink to='/login' onClick={onLogout} className="navbar__link">התנתק</NavLink>
            </li>
        </ul>
    ) :
        (
            <ul className="navbar__links">
                <li >
                    <NavLink to='/control' className="navbar__link" >וואטסאפ קבוצתי</NavLink>
                </li>
                <li >
                    <NavLink to='/login' onClick={onLogout} className="navbar__link">התנתק</NavLink>
                </li>
            </ul>
        )
        ;

    const guestLinks = (
        <ul className="navbar__links">
            <li >
                <NavLink to='/login' className="navbar__link">התחבר</NavLink>
            </li>
        </ul>
    );
    if (url.includes('docs')) return null
    return (
        <nav className="navbar bg-dark">
            {!authState.loading && (<>{authState.isAuthenticated ? authLinks : guestLinks}</>)}

            <h1 className="navbar__logo">
                {/* <NavLink to="/"> */}
                <img className='logo' src={logo1} alt="logo1" />
                <img className='logo' src={logo2} alt="logo2" />
                {/* </NavLink> */}
            </h1>
        </nav>

    )
}

export default Navbar