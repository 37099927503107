import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux'
import { addControl } from '../../../store/flight/flightAction'

import Passenger from './Passenger.js'

const Control = () => {

    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const flightState = useSelector(state => state.flight);
    var tzoffset = (new Date(0)).getTimezoneOffset() - 7200000; //offset in milliseconds

    const { doneUpload, startUpload, kav } = flightState
    useEffect(() => {
        if (doneUpload) {
            setTripState({
                tripTitle: '',
                tripNumber: '',
                is_flight: false,
                tripDate: '',
                pdfFiles: [],
                filesNames: [],
                folderName: '',
                msg: '',
                error: '',
                dateFormatted: '',
                uploadEnd: false,
                kav: {}

            })
        }
    }, [doneUpload, dispatch])

    useEffect(() => {
        if (startUpload) {
            setTripState({
                tripTitle: '',
                tripNumber: '',
                is_flight: false,
                tripDate: (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1),
                pdfFiles: [],
                filesNames: [],
                folderName: '',
                msg: '',
                error: '',
                dateFormatted: '',
                uploadEnd: false,
                startUpload: true,
                kav: {}
            })
        }
    }, [startUpload, tzoffset, dispatch])

    useEffect(() => {
        if (flightState.error)
            setTripState({ ...tripState, error: flightState.error, uploadEnd: true })
    }, [flightState.error, dispatch])

    useEffect(() => {
        if (kav)
            setTripState({ ...tripState, kav: flightState.kav })
    }, [kav, dispatch])


    const [tripState, setTripState] = useState({
        tripTitle: '',
        tripNumber: '',
        is_flight: false,
        tripDate: '',
        pdfFiles: [],
        filesNames: [],
        folderName: '',
        msg: '',
        error: '',
        dateFormatted: '',
        uploadEnd: false,
        kav: {}
    })
    const { tripNumber, tripDate, filesNames, pdfFiles, folderName, tripTitle } = tripState


    const currencyToShowArr = { '1': '₪' }
    const onChange = e => {
        const target = e.target;
        let value = target.type === 'select-one' ? target.value === "true" : target.value;
        setTripState({ ...tripState, [target.name]: value });
    }


    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('tripNumber', tripNumber);
        formData.append('tripTitle', tripTitle);
        // if (pdfFiles) {
        //     pdfFiles.forEach(pdfFile => {
        //         formData.append('pdfFiles', pdfFile);
        //     })
        // }
        // formData.append('filesNames', filesNames);
        let currentTripState = { ...tripState, tripDate: (new Date(Date.now() - tzoffset)).toISOString() }
        // dispatch(uploadFiles(formData))
        // do stuff - get passenger names from pdf files, get trip api from Kav system, save to db
        dispatch(addControl(currentTripState, formData))
    }

    if (!authState.isAuthenticated) {
        return <Navigate to='/login' />
    }
    if (authState?.user?.role === 'guider') {
        return <Navigate to='/whats-app' />
    }
    return (
        <div className='control'>
            <div className="control-container">
                <div className="control-top-info">
                    <div className="input-container">
                        <input onChange={onChange} className='input form__field' id="tripTitle" name="tripTitle" type="text" value={tripTitle} />
                        <label htmlFor="tripTitle" className="label-name"> שם טיול</label>
                    </div>
                    <div className="input-container">
                        <input onChange={onChange} className='input form__field' id="tripNumber" name="tripNumber" type="text" value={tripNumber} />
                        <label htmlFor="tripNumber" className="label-name">קוד טיול:</label>
                        <span>{`*ישלח למערכת קו "${tripNumber ? tripNumber.toUpperCase() : 'RN:2206'}"`}</span>
                    </div>

                    <div className="input-container">
                        <select id="is_flight" onChange={onChange} name="is_flight" defaultValue={tripState.is_flight ? "TURG" : "TURC"}>
                            <option value="true">TURG</option>
                            <option value="false">TURC</option>
                        </select>
                    </div>

                    <button className='btn' onClick={onSubmit} >הוסף טיול</button>
                </div>

                <div className="control-kav">
                    <div className="control-kav-top">

                        <div className="relative-btn">
                            <button className='btn'>שייך אנשים</button>
                        </div>


                        <div className="dockets">
                            <div className='docket-card'>
                                {kav ? Object.keys(kav).length > 2 ? kav?.map((docket, index) => {
                                    const currency = docket.Balance.Currency
                                    return (
                                        <div className={`docket-card`} key={index}>
                                            {/* <div className={`docket-card ${currency.Difference._text >= 5 ? 'red' : null}`} key={index}> */}
                                            <div className="docket-card-top">
                                                <div className="docket-card-top-left">
                                                    <p className='docket-id'>מספר הדוקט: {docket.ID._text} </p>
                                                    {/* <p className='docket-paid'>עלות: {currency.Paid._text} {currencyToShowArr[currency?.Code?._text]}</p> */}
                                                    {/* <p className='docket-difference'>יתרה: {currency.Difference._text} {currencyToShowArr[currency?.Code?._text]}</p> */}
                                                </div>
                                            </div>
                                            <div className='docket-card-bottom'>
                                                {docket.Paxes?.Pax?.length > 1 ? docket.Paxes.Pax.map((pax, index) => {
                                                    return <Passenger pax={pax} index={index} />
                                                }) : <Passenger pax={docket.Paxes.Pax} index={index} />
                                                }
                                            </div>
                                        </div>)

                                }) : 'לשלוח קוד טיול לקבל פרטים' : null
                                }

                                {/* <Passenger /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Control