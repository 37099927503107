import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { getProtectedPdf } from '../../store/pdf/pdfAction';
import { Viewer, Worker } from '@react-pdf-viewer/core';

const ProtectedPdf = () => {
    const dispatch = useDispatch();
    const location = useLocation(); // Get the current location
    const params = new URLSearchParams(location.search);
    const filePath = params.get('filePath'); // Get the filePath query parameter
    useEffect(() => {
        if (filePath) {
            dispatch(getProtectedPdf('123', filePath)); // Dispatch the action with filePath
        }
    }, [dispatch, filePath]);

    const pdfData = useSelector(state => state.pdf);

    return (
        <div>
            {pdfData.pdf && (
                <Worker workerUrl={filePath}>
                    <Viewer fileUrl={{ data: pdfData.pdf }} />
                </Worker>
            )}
        </div>
    );
};

export default ProtectedPdf;
