import React, { useState, useEffect } from 'react'

import Dropzone from 'react-dropzone'
import XInsideSolidCircle from '../icons/XInsideSolidCircle.js'
import PlusInCircle from '../icons/PlusInCircle.js'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomMail } from '../../store/customMail/customMailAction'

import { Navigate } from 'react-router-dom';
import UploadingAnimation from '../layout/UploadingAnimation'
import Alert from '../layout/Alert'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Wysiwyg from '../layout/Wysiwyg.js'

const SendMailPage = () => {

    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const flightState = useSelector(state => state.flight);
    var tzoffset = (new Date(0)).getTimezoneOffset() - 7200000; //offset in milliseconds

    useEffect(() => {
        if (flightState.doneUpload) {
            setCustomMailState({
                title: '',
                tripNumber: '',
                is_flight: true,
                tripDate: '',
                files: [],
                filesNames: [],
                wysiwyg: '',
                error: '',
                uploadEnd: false
            })
        }
    }, [flightState.doneUpload, dispatch])

    useEffect(() => {
        if (flightState.startUpload) {
            setCustomMailState({
                title: '',
                tripNumber: '',
                is_flight: true,
                tripDate: (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1),
                files: [],
                filesNames: [],
                wysiwyg: '',
                error: '',
                uploadEnd: false,
                startUpload: true,
            })
        }
    }, [flightState.startUpload, tzoffset, dispatch])
    const [customMailState, setCustomMailState] = useState({
        title: '',
        tripNumber: '',
        tripDate: '',
        is_flight: true,
        files: [],
        filesNames: [],
        wysiwyg: '',
        error: '',
        uploadEnd: false,
    })
    const { tripNumber, tripDate, filesNames, files, title } = customMailState

    const onChange = e => {
        const target = e.target;
        let value = target.type === 'select-one' ? target.value === "true" : target.value;
        setCustomMailState({ ...customMailState, [target.name]: value });
    }

    // const [editorState, setEditorState] = useState({
    //     initValue: 'test',
    // })

    const handleWysiwyg = (wysiwygValue) => {
        setCustomMailState({
            ...customMailState,
            wysiwyg: wysiwygValue
        })
    }

    const filesToShow = filesNames ? filesNames.map((file, index) => {
        return <li key={index} className="files-file">{file}</li>
    }) : null;

    const handleReset = () => {
        setCustomMailState({
            tripNumber: '',
            is_flight: true,
            tripDate: '',
            files: [],
            filesNames: [],
            wysiwyg: '',
            error: '',
            uploadEnd: false
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('tripNumber', tripNumber);
        formData.append('tripDate', tripDate);
        formData.append('title', title);
        if (files) {
            files.forEach(pdfFile => {
                formData.append('files', pdfFile);
            })
        }
        formData.append('filesNames', filesNames);
        let currentTripState = { ...customMailState, tripDate: (new Date(Date.now() - tzoffset)).toISOString() }
        dispatch(addCustomMail(currentTripState, formData))
    }


    const onDrop = (newFiles) => {
        Array.from(files).forEach(file => { filesNames.push(file.name) })
        let newPdfState = [...files, ...newFiles]
        // const newPdfNames = [...filesNames, ...newFiles.map(file => file.name)]

        newPdfState = newPdfState.reduce((accumulator, current) => {
            if (!accumulator.some((item) => item.name === current.name))
                accumulator.push(current);
            return accumulator;
        }, []);
        const concatFileNames = [...filesNames, ...newFiles.map(file => file.name)]
        const newFilesNames = [...new Set(concatFileNames)];

        setCustomMailState({
            ...customMailState,
            filesNames: newFilesNames,
            files: newPdfState
        })
    }

    if (!authState.isAuthenticated) {
        return <Navigate to='/login' />
    }
    if (authState?.user?.role === 'guider') {
        return <Navigate to='/whats-app' />
    }
    return (
        <div className="custom-mail-container">
            <form onSubmit={onSubmit}>
                <div className="">
                    {/* <UploadingAnimation startUpload={flightState.startUpload} /> */}
                    <div className='top-inputs'>

                        <div className="input-container">
                            <input onChange={onChange} className='input form__field' id="tripNumber" name="tripNumber" type="text" value={tripNumber} />
                            <label htmlFor="tripNumber" className="label-name">קוד טיול:</label>
                            <span>{`*ישלח למערכת קו "${tripNumber ? tripNumber.toUpperCase() : 'RN:2206'}"`}</span>
                        </div>
                        {/* <div className="input-container checkbox">
                            <input type="checkbox" id="is_flight" onChange={onChange} name="is_flight" checked={customMailState.is_flight} />
                            <label htmlFor="is_flight">{customMailState.is_flight ?
                                "TURG" :
                                "TURC"}
                            </label>
                        </div> */}

                        <div className="input-container ">
                            <select id="is_flight" onChange={onChange} name="is_flight" defaultValue={customMailState.is_flight ? "TURG" : "TURC"}>
                                <option value="true">TURG</option>
                                <option value="false">TURC</option>
                            </select>
                        </div>
                    </div>


                    <div className="input-container small">
                        <input onChange={onChange} className='input form__field' id="title" name="title" type="text" value={title} />
                        <label htmlFor="title" className="label-name"> כותרת למייל</label>
                    </div>

                    <Wysiwyg customMailState={customMailState} setCustomMailState={setCustomMailState} handleWysiwyg={handleWysiwyg} />

                    <Dropzone onDrop={onDrop} multiple >

                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <div {...getRootProps()} className="bottom">
                                {!isDragActive && <img src="https://thumbs.dreamstime.com/b/drag-drop-symbol-concept-icon-flat-isolated-eps-illustration-minimal-modern-design-96340345.jpg" alt="img" width={150} height={150} />}
                                {isDragActive && !isDragReject && <PlusInCircle className={'upload-form'} />}
                                <input {...getInputProps()} />
                                {!isDragActive &&
                                    <div className="text">
                                        <p className='drag'>הוספ/י קבצים לפה</p>
                                        <p className='or'>או</p>
                                        <p className='click'>לחץ/י להעלאה</p>
                                    </div>}
                                {isDragActive && !isDragReject && "אפשר לשחרר כאן"}
                            </div>
                        )}
                    </Dropzone>
                    <div className="files-status">
                        {filesToShow.length > 0 ? <ul>{filesToShow}</ul> : null}
                    </div>


                    <div className="input-container">
                        <input type="submit" value="שליחה" className='btn btn-secondary' />
                        <div className="reset-files" onClick={handleReset}>נקה נתונים</div>
                    </div>
                </div>




                <Alert />
            </form>
        </div>
    )
}

export default SendMailPage

