import {
    GET_KAV_START,
    GET_KAV_END,
    SET_KAV,
    GET_FLIGHTS,
    GET_FLIGHT,
    LOAD_MORE_FLIGHTS,
    ADD_FLIGHT,
    EDIT_FLIGHT,
    DELETE_FLIGHT,
    FLIGHT_ERROR,
    CLEAR_FLIGHT,
    ADD_FILES,
    GET_PDF_NAMES,
    LOADING_START,
    LOADING_END,
    DONE_UPLOADING,
    START_UPLOADING,
    UPLOAD_PROGRESS
} from './flightTypes';

const initialState = {
    loading: false,
    error: {},
    expire_at: {},
    tripNumber: "",
    filesNames: [],
    flights: [],
    pdfFiles: [],
    passengers: [],
    flight: {},
    is_flight: false,
    tripDate: "",
    doneUpload: false,
    uploadProgress: 0,
    page: 1,
    emailText: "",
}

export default function flightReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case START_UPLOADING:
            return {
                ...state,
                startUpload: true
            }
        case GET_FLIGHTS:
            return {
                ...state,
                flights: payload.flights,
                page: payload.page,
                totalPages: payload.totalPages,
                totalFlights: payload.totalFlights,
                loading: false
            };
        case GET_FLIGHT:
            return {
                ...state,
                ...payload,
                loading: false
            };

        case LOAD_MORE_FLIGHTS:
            return {
                ...state,
                flights: [...state.flights, ...payload.flights],
                page: state.page + 1,
                error: null
            };

        case ADD_FILES:
            let newPdfFiles = [...new Set([...state.pdfFiles, ...payload.pdfFiles])];
            let newFileNames = [...state.filesNames, ...payload.filesNames];
            return {
                ...state,
                pdfFiles: newPdfFiles,
                filesNames: newFileNames,
                loading: false
            };

        case ADD_FLIGHT:
            return {
                ...state,
                ...payload,
                loading: false,
            };


        case EDIT_FLIGHT:
            return {
                ...state,
                ...payload,
                loading: false
            };

        case DELETE_FLIGHT:
            return {
                ...state,
                flights: state.flights.filter(flight => flight._id !== payload),
                loading: false
            };
        case FLIGHT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case CLEAR_FLIGHT:
            return {
                ...state,
                loading: false,
                error: {},
                expire_at: {},
                tripNumber: '',
                filesNames: [],
                pdfFiles: [],
                passengers: [],
                flight: {},
                tripDate: '',
                doneUpload: false,
                startUpload: false,
                uploadProgress: 0,
                user: '',
                pdfName: [],
                folderName: '',
                _id: '',
                date: '',
            };
        case DONE_UPLOADING:
            return {
                ...state,
                loading: false,
                doneUpload: true
            };

        case GET_PDF_NAMES:
            return {
                ...state,
                userDetails: payload,
                loading: false
            };

        case LOADING_START:
            return {
                ...state,
                loading: true
            };
        case LOADING_END:
            return {
                ...state,
                loading: false
            };

        case UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: payload
            };

        case GET_KAV_START:
            return {
                ...state,
                loading: true
            };
        case GET_KAV_END:
            return {
                ...state,
                loading: false
            };
        case SET_KAV:
            return {
                ...state,
                kav: payload,
                loading: false
            };

        default:
            return state;
    }
}