export const ADD_CUSTOM_EMAIL = 'ADD_CUSTOM_EMAIL';
export const GET_CUSTOM_EMAILS = 'GET_CUSTOM_EMAILS';
export const GET_CUSTOM_EMAIL = 'GET_CUSTOM_EMAIL';
export const EDIT_CUSTOM_EMAIL = 'EDIT_CUSTOM_EMAIL';
export const DELETE_CUSTOM_EMAIL = 'DELETE_CUSTOM_EMAIL';
export const FLIGHT_ERROR = 'FLIGHT_ERROR';
export const CLEAR_CUSTOM_EMAIL = 'CLEAR_CUSTOM_EMAIL';
export const ADD_FILES = 'ADD_FILES';
export const GET_PDF_NAMES = 'GET_PDF_NAMES';
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const DONE_UPLOADING = 'DONE_UPLOADING';
export const START_UPLOADING = 'START_UPLOADING';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
