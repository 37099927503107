import React from 'react'

const WhatssappDemo = (props) => {
    const { tripNumber, tripGuider, freeText, whatsapp_group_id, whatsapp_group_URL } = props
    return (
        <div>
            <p>
                {`
                נוסעים יקרים,
            
                `}
            </p>
            <br />
            <p>
                {`
                 אנו מזמינים אתכם להצטרף לקבוצת הווטסאפ של משתתפי הטיול ${tripGuider ? tripGuider : '$שם הטיול$'}
            `}
            </p>
            <br />

            <p>
                {`בשלב הזה לא ניתן לשלוח הודעות, הקבוצה תיפתח לשיח אך ורק לאחר מפגש הקבוצה שיתקיים בזום ${freeText ? freeText : '$טקסט חופשי$'}.`}
            </p>
            <br />

            <p>
                {`
                להצטרפות לקבוצת הווטסאפ אנא לחצו כעת על הקישור הבא: ${whatsapp_group_URL ? whatsapp_group_URL : '$https://chat.whatsapp.com/$'} ,
                 לאחר מכן לחצו על הכפתור "הצטרף לקבוצה"
                ואשרו את הצטרפותכם
            `}
            </p>
            <p>
                {`
                בברכה,
                צוות בשביל הזהב וקרוזתור
            `}

            </p>
        </div >
    )
}

export default WhatssappDemo