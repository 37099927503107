import {
    LOADING_START,
    LOADING_STOP,
    GET_PDF_FILE,
    PDF_ERROR
} from './pdfTypes';

const initialState = {
    pdfUrl: null,
    error: null,
    loading: false
}

export default function pdfReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOADING_START:
            return {
                ...state,
                loading: true
            }

        case LOADING_STOP:
            return {
                ...state,
                loading: false
            }

        case GET_PDF_FILE:
            return {
                ...state,
                pdfUrl: payload
            }

        case PDF_ERROR:
            return {
                ...state,
                pdfUrl: null,
                error: payload
            }


        default:
            return state;
    }
}