export const GET_KAV_START = 'GET_KAV_START';
export const GET_KAV_END = 'GET_KAV_END';
export const SET_KAV = 'SET_KAV';
export const ADD_FLIGHT = 'ADD_FLIGHT';
export const LOAD_MORE_FLIGHTS = 'LOAD_MORE_FLIGHTS,';
export const GET_FLIGHTS = 'GET_FLIGHTS';
export const GET_FLIGHT = 'GET_FLIGHT';
export const EDIT_FLIGHT = 'EDIT_FLIGHT';
export const DELETE_FLIGHT = 'DELETE_FLIGHT';
export const FLIGHT_ERROR = 'FLIGHT_ERROR';
export const CLEAR_FLIGHT = 'CLEAR_FLIGHT';
export const ADD_FILES = 'ADD_FILES';
export const GET_PDF_NAMES = 'GET_PDF_NAMES';
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const DONE_UPLOADING = 'DONE_UPLOADING';
export const START_UPLOADING = 'START_UPLOADING';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
