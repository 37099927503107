import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import FlightCard from '../layout/FlightCard'
import { getFlights, formatDate, loadMoreFlights } from '../../store/flight/flightAction'
import Loader from '../layout/Loader';
import Alert from '../layout/Alert';
window.Buffer = window.Buffer || require("buffer").Buffer;

const Landing = () => {
    const dispatch = useDispatch();
    const flightState = useSelector(state => state.flight);
    const authState = useSelector(state => state.auth);
    const observer = useRef();
    const { flights, page, loading, totalPages, totalFlights } = flightState
    useEffect(() => {
        if (flights.length === 0)
            dispatch(getFlights())
        return () => { }
    }, [dispatch])
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5
        };

        const handleObserver = (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !loading)
                dispatch(loadMoreFlights(page + 1));
        };

        observer.current = new IntersectionObserver(handleObserver, options);

        return () => { observer.current.disconnect(); };
    }, [dispatch, page, loading]);
    useEffect(() => {
        if (observer.current) {
            observer.current.disconnect();
        }
        observer.current.observe(document.getElementById('observer-marker'));
    }, [flights]);
    const [tripState, setTripState] = useState({
        tripNumber: '',
        tripDate: '',
        searchInput: '',
        pdfFiles: [],
        filesNames: [],
        searchArr: [],
        msg: '',
        error: '',
        dateFormatted: ''
    })
    const { searchInput, searchArr } = tripState

    const is_flights = flights ? flights.length > 0 ? true : false : null

    const loadMore = () => {
        dispatch(loadMoreFlights(page + 1));
    };


    const onSearch = (e) => {
        const searchArr = flightState.flights.filter(flight => flight.tripNumber.includes(e.target.value.toUpperCase()))
        setTripState({
            ...tripState,
            searchArr,
            searchInput: e.target.value
        })
    }

    if (!authState.isAuthenticated) {
        return <Navigate to='/login' />
    }
    if (authState?.user?.role === 'guider') {
        return <Navigate to='/whats-app' />
    }
    return (
        <div className="landing" >
            {flightState.loading ? <Loader /> : null}
            <section className="container">
                <h1 className="large text-primary">רשימת טיולים</h1>

                {totalFlights && <div className='totalFlights'>
                    <p className='totalFlights_text'>סך הכל <span className='totalFlights_number'>{totalFlights} </span>  טיולים</p>
                </div>}
                <div className="input-container landing__input">
                    <input className='input form__field' type="text" name='search' id="search" onChange={onSearch} value={searchInput} />
                    <label htmlFor='search' className="label-name">חיפוש מספר טיול</label>
                </div>

                <div className="flights">
                    {is_flights ?
                        searchArr.length > 0 ?
                            searchArr.map((flight, index) => {
                                const dateFormatted = formatDate(flight.tripDate)
                                return <FlightCard key={index} index={index} tripNumber={flight.tripNumber} tripDate={flight.tripDate} _id={flight._id} dateFormatted={dateFormatted} folderName={flight.folderName} tripTitle={flight.tripTitle} />
                            }
                            )
                            :
                            flightState.flights.map((flight, index) => {
                                const dateFormatted = formatDate(flight.tripDate)
                                return <FlightCard key={index} index={index} tripNumber={flight.tripNumber} tripDate={flight.tripDate} _id={flight._id} dateFormatted={dateFormatted} folderName={flight.folderName} tripTitle={flight.tripTitle} />
                            }
                            )
                        :
                        <h2>אין טיסות</h2>
                    }
                </div>
            </section>
            <div id="observer-marker" style={{ height: '1px' }}></div>
            <Alert />
        </div>
    )
}

export default Landing