import React, { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Wysiwyg = (props) => {
    const { customMailState, setCustomMailState, editorState, setEditorState, handleWysiwyg } = props

    return (
        <div className='wysiwyg'>
            <Editor
                // apiKey='rmq9os9ulm77m3109353phtifh3rsnh8qbn3vf0kxp43zzlg'
                apiKey='rr54zoicxkt3ah4i2h3xynyo16biuentcxqycps7ep8l9b0f'
                onInit={(evt, editor) => customMailState.wysiwyg = editor}
                initialValue='
                <div>
                    — <br>
                <p>&nbsp;בברכה,&nbsp; <br></p>
                    <p>&nbsp;צוות קרוז תור ובשביל הזהב&nbsp;</p>
                </div>
                '
                onChange={(e) => { handleWysiwyg(e.level.content) }}
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px;direction:rtl; }'
                }}
            />
            {/* <button onClick={log}>Log editor content</button> */}
        </div>
    )
}

export default Wysiwyg