import React, { useState, useEffect } from 'react'
// import folderImage from '../../assets/img/folder.webp'
import Dropzone from 'react-dropzone'
import XInsideSolidCircle from '../icons/XInsideSolidCircle.js'
import PlusInCircle from '../icons/PlusInCircle.js'
import { useDispatch, useSelector } from 'react-redux'
import { addFlight } from '../../store/flight/flightAction'
// import PdfTest from '../layout/PdfTest'
import { Navigate, redirect } from 'react-router-dom';
import UploadingAnimation from '../layout/UploadingAnimation'
import Alert from '../layout/Alert'

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import WysiwygAddFlight from '../layout/WysiwygAddFlight.js'



const AddTrip = () => {

    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);
    const flightState = useSelector(state => state.flight);
    var tzoffset = (new Date(0)).getTimezoneOffset() - 7200000; //offset in milliseconds

    useEffect(() => {
        if (flightState.doneUpload) {
            setTripState({
                tripTitle: '',
                tripNumber: '',
                is_flight: true,
                tripDate: '',
                pdfFiles: [],
                filesNames: [],
                folderName: '',
                msg: '',
                error: '',
                dateFormatted: '',
                uploadEnd: false,
                emailText: ''
            })
        }
    }, [flightState.doneUpload, dispatch])

    useEffect(() => {
        if (flightState.startUpload) {
            setTripState({
                tripTitle: '',
                tripNumber: '',
                is_flight: true,
                tripDate: (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1),
                pdfFiles: [],
                filesNames: [],
                folderName: '',
                msg: '',
                error: '',
                dateFormatted: '',
                uploadEnd: false,
                startUpload: true,
                emailText: ''
                // uploadProgress: flightState.progress ? flightState.progress : 0
            })
        }
    }, [flightState.startUpload, tzoffset, dispatch])
    const [tripState, setTripState] = useState({
        tripTitle: '',
        tripNumber: '',
        is_flight: true,
        tripDate: '',
        pdfFiles: [],
        filesNames: [],
        folderName: '',
        msg: '',
        error: '',
        dateFormatted: '',
        uploadEnd: false,
        emailText: ''
        // uploadProgress: flightState.progress ? flightState.progress : 0

    })
    const { tripNumber, tripDate, filesNames, pdfFiles, folderName, tripTitle, emailText } = tripState

    const onChange = e => {
        const target = e.target;
        let value = target.type === 'select-one' ? target.value === "true" : target.value;
        setTripState({ ...tripState, [target.name]: value });
    }

    const handleWysiwyg = (wysiwygValue) => {
        setTripState({
            ...tripState,
            emailText: wysiwygValue
        })
    }
    const filesToShow = filesNames ? filesNames.map((file, index) => {
        return <li key={index} className="files-file">{file}</li>
    }) : null;

    const handleReset = () => {
        setTripState({
            tripNumber: '',
            is_flight: true,
            tripDate: '',
            pdfFiles: [],
            filesNames: [],
            folderName: '',
            msg: '',
            error: '',
            dateFormatted: '',
            uploadEnd: false,
            emailText: ''
        })
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('tripNumber', tripNumber);
        formData.append('tripTitle', tripTitle);
        formData.append('tripDate', tripDate);
        formData.append('folderName', folderName.length > 0 ? folderName : new Date().getTime());
        formData.append('emailText', emailText);
        if (pdfFiles) {
            pdfFiles.forEach(pdfFile => {
                formData.append('pdfFiles', pdfFile);
            })
        }
        formData.append('filesNames', filesNames);
        let currentTripState = { ...tripState, tripDate: (new Date(Date.now() - tzoffset)).toISOString() }
        // dispatch(uploadFiles(formData))
        // do stuff - get passenger names from pdf files, get trip api from Kav system, save to db
        dispatch(addFlight(currentTripState, formData))
    }

    const onDrop = (newPdfFiles) => {
        Array.from(pdfFiles).forEach(file => {
            console.log(file)
            filesNames.push(file.name)
        })
        let newPdfState = [...pdfFiles, ...newPdfFiles]
        // const newPdfNames = [...filesNames, ...newPdfFiles.map(file => file.name)]

        newPdfState = newPdfState.reduce((accumulator, current) => {
            if (!accumulator.some((item) => item.name === current.name))
                accumulator.push(current);
            return accumulator;
        }, []);
        const concatFileNames = [...filesNames, ...newPdfFiles.map(file => file.name)]
        const newFilesNames = [...new Set(concatFileNames)];

        setTripState({
            ...tripState,
            filesNames: newFilesNames,
            pdfFiles: newPdfState
        })
    }

    if (!authState.isAuthenticated) {
        return <Navigate to='/login' />
    }
    if (authState?.user?.role === 'guider') {
        return <Navigate to='/whats-app' />
    }
    return (
        <div className="add-trip-container">
            <form onSubmit={onSubmit}>
                <div className="right">
                    <UploadingAnimation startUpload={flightState.startUpload} />
                    <div className="input-container">
                        <input onChange={onChange} className='input form__field' id="tripTitle" name="tripTitle" type="text" value={tripTitle} />
                        <label htmlFor="tripTitle" className="label-name"> שם טיול</label>
                    </div>
                    <div className="input-container">
                        <input onChange={onChange} className='input form__field' id="tripNumber" name="tripNumber" type="text" value={tripNumber} />
                        <label htmlFor="tripNumber" className="label-name">קוד טיול:</label>
                        <span>{`*ישלח למערכת קו "${tripNumber ? tripNumber.toUpperCase() : 'RN:2206'}"`}</span>
                    </div>

                    <div className="input-container ">
                        <select id="is_flight" onChange={onChange} name="is_flight" defaultValue={tripState.is_flight ? "TURG" : "TURC"}>
                            <option value="true">TURG</option>
                            <option value="false">TURC</option>
                        </select>
                    </div>

                    <span style={{ fontSize: '1rem' }}>
                        לשליחת כרטיסים ל  TURC
                        יש להשאיר את הצ׳ק בוקס מעלה ריק</span>
                    <input type="submit" value="שליחה" className='btn btn-secondary' />
                    <div className="reset-files" onClick={handleReset}>נקה נתונים</div>

                    <div className={flightState.startUpload ? `CircularProgressbar-container show` : `CircularProgressbar-container hide`}>
                        {flightState.uploadProgress === 100 ? <p>נא לא לסגור את החלון - מבצע קסם מאחורי הקלעים</p> : null}
                        < CircularProgressbar value={flightState.uploadProgress} text={`${flightState.uploadProgress}%`} />
                    </div>

                    <div className="files-status">
                        {filesToShow.length > 0 ? <ul>{filesToShow}</ul> : null}
                    </div>
                </div>


                <div className="left">
                    <WysiwygAddFlight flightState={tripState} onChange={handleWysiwyg} />
                    {/* <div className='input-container'>
                        <textarea onChange={onChange} className='input textarea form__field' id="emailText" name="emailText" type="text" value={emailText} />
                        <label htmlFor="emailText" className="label-name">טקסט למייל</label>
                    </div> */}
                    <Dropzone onDrop={onDrop} multiple >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <div {...getRootProps()} className="left-dropzone">
                                {!isDragActive && <img src="https://thumbs.dreamstime.com/b/drag-drop-symbol-concept-icon-flat-isolated-eps-illustration-minimal-modern-design-96340345.jpg" alt="img" width={150} height={150} />}
                                {isDragActive && !isDragReject && <PlusInCircle className={'upload-form'} />}
                                {isDragReject && <XInsideSolidCircle className={'upload-form'} />}
                                <input {...getInputProps()} />
                                {!isDragActive &&
                                    <div className="text">
                                        <p className='drag'>הוספ/י קבצים לפה</p>
                                        <p className='or'>או</p>
                                        <p className='click'>לחץ/י להעלאה</p>
                                    </div>}
                                {isDragActive && !isDragReject && "אפשר לשחרר כאן"}
                                {isDragReject && "טעות בקובץ"}


                            </div>
                        )}
                    </Dropzone>
                </div>
            </form>
            <Alert />
        </div>
    )
}

export default AddTrip